<template>
  <div class="jh-dialog-wrap">
    <div class="jh-dialog-header">
      <h1>REPEAT 즐겨찾기 설정</h1>
      <div class="is-right">
        <v-btn class="jh-btn is-icon" title="닫기" @click="$emit('hide')">
          <i class="jh-icon-close-lg is-white"/>
        </v-btn>
      </div>
    </div>
    <div class="jh-dialog-body">
      <div class="jh-search-form">
        <table>
          <colgroup>
            <col width="60px" />
            <col width="120px" />
            <col />
          </colgroup>
          <tr>
            <th><label>사용여부</label></th>
            <td>
              <v-select
                class="jh-form"
                :items="usedCodeList"
                v-model="searchUseYN"
              />
            </td>
            <td class="has-search">
              <v-btn class="jh-btn is-search" @click="search">조회</v-btn>
            </td>
          </tr>
        </table>
      </div>
      <data-tables
        ref="dataTable"
        :data-table-options="dataTableOptions"
        @click:row="onClickRow"
        :paginationOptions="paginationOptions"
        v-model="selectedRows"
      />
      <div class="jh-ui-header">
        <h2>REPEAT 상세정보</h2>
        <div class="is-right"></div>
      </div>
      <table class="jh-tbl-detail">
        <colgroup>
          <col width="90px" />
          <col />
          <col width="90px" />
          <col />
          <col width="90px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th><label>상담유형</label></th>
            <td colspan="5">
              <div class="jh-cols">
                <v-select
                  class="jh-form"
                  :items="mainCategoryList"
                  placeholder="선택"
                  v-model="repeatDetail.MAIN_CTGR_CD"
                />
                <v-select
                  class="jh-form"
                  :items="middleCategoryList"
                  placeholder="선택"
                  v-model="repeatDetail.MID_CTGR_CD"
                />
                <v-select
                  class="jh-form is-col-fix"
                  style="width: 220px"
                  :items="subCategoryList"
                  placeholder="선택"
                  v-model="repeatDetail.SUB_CTGR_CD"
                />
              </div>
            </td>
          </tr>
          <tr>
            <th><label>사용여부</label></th>
            <td>
              <v-select
                class="jh-form"
                :items="filteredUsedCodeList()"
                placeholder="선택"
                v-model="repeatDetail.USE_YN"
              />
            </td>
            <th><label>정렬순서</label></th>
            <td>
              <v-text-field
                class="jh-form"
                v-model="repeatDetail.SORT_ORD"
              />
            </td>
            <th><label>등록자</label></th>
            <td>
              <v-text-field
                class="jh-form is-trns"
                readonly
                v-model="repeatDetail.REG_NM"
              />
            </td>
          </tr>
          <tr>
            <th><label>상담내용</label></th>
            <td colspan="5">
              <v-textarea
                class="jh-form"
                noResize
                placeholder="상담내용을 입력하세요."
                style="height: 125px"
                v-model="repeatDetail.CONTENT"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="jh-dialog-footer">
      <v-btn class="jh-btn is-md" @click="reset">초기화</v-btn>
      <v-btn class="jh-btn is-md is-main" v-if="mixin_set_btn($options.name, 'btnSave')" :loading="isLoading" @click="save">저장</v-btn><!-- :disabled="!valid" -->
      <v-btn class="jh-btn is-md is-del" v-if="mixin_set_btn($options.name, 'btnDelete')" :disabled="!repeatDetail.ID" :loading="isLoading" @click="this.delete">삭제</v-btn>
    </div>
  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";
import { mapGetters } from "vuex";

export default {
  name: "MENU_E010101P01", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    dataTables,
  },
  props: {},
  data() {
    return {
      isLoading: false,

      usedCodeList: [
        { text: "전체", value: null },
        { text: "사용", value: "Y" },
        { text: "사용안함", value: "N" },
      ],
      searchUseYN: null,

      mainCategoryList: [],
      middleCategoryList: [],
      subCategoryList: [],

      dataTableOptions: {
        fixedHeader: true,
        headers: [
          { text: "순번", value: "ROWNUM", align: "center", width: "50px" },
          { text: "대분류", value: "MAIN_CTGR_NM", width: "150px" },
          { text: "중분류", value: "MID_CTGR_NM", width: "180px" },
          { text: "소분류", value: "SUB_CTGR_NM" },
          { text: "정렬순서", value: "SORT_ORD", align: "center", width: "80px" },
        ],
        height: "250px",
        hideDefaultFooter: true,
        itemKey: "ID",
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        singleSelect: true,

        load: this.getList,
      },
      paginationOptions: {
        totalVisible: 10,
      },

      requestData: {
        headers: {
          SERVICE: "phone.main",
          TYPE: "BIZ_SERVICE",
        },
        sendData: {},
      },

      selectedKey: null,
      selectedRows: [],

      repeatDetail: {
        ID: null,
        MAIN_CTGR_CD: null,
        MAIN_CTGR_NM: null,
        MID_CTGR_CD: null,
        MID_CTGR_NM: null,
        SUB_CTGR_CD: null,
        SUB_CTGR_NM: null,
        CONTENT: null,
        SORT_ORD: null,
        USE_YN: null,
        REG_ID: null,
        REG_NM: null,
      },
    };
  },
  computed: {
    dataTableInstance() {
      return this.$refs.dataTable;
    },
    ...mapGetters({
      USER_ID: "userStore/GE_USER_ID",
    }),
  },
  methods: {
    init: async function() {
      // 헤더 초기화 및 세팅
      this.setDataTableParams();

      const resCategoryList = await this.getCategoryList();

      if (
        !this.mixin_isEmpty(resCategoryList) &&
        !resCategoryList.HEADER.ERROR_FLAG
      ) {
        this.mainCategoryList = resCategoryList.DATA;
      }
    },
    getCategoryList: function(parentCode) {
      // header 세팅
      let requestData = {
        headers: {
          ASYNC: false,
          METHOD: "list",
          SERVICE: "phone.main",
          TYPE: "BIZ_SERVICE",
          URL: "/api/phone/main/repeat/category/list"
        },
        sendData: {},
      };

      // sendData 세팅
      requestData.sendData["PRNT_CD"] = parentCode;

      return this.common_postCall(requestData);
    },
    setDataTableParams() {
      // header 세팅
      this.requestData.headers["URL"] = "/api/phone/main/repeat/list";
      this.requestData.headers["METHOD"] = "list";
      this.requestData.headers["ASYNC"] = false;

      // sendData 세팅
      this.requestData.sendData["USE_YN"] = this.searchUseYN;
      this.requestData.sendData["USER_ID"] = this.USER_ID;
    },
    getList: async function(loadOptions) {
      // 페이징 정보 세팅
      this.requestData.headers["ROW_CNT"] = loadOptions.itemsPerPage;
      this.requestData.headers["PAGES_CNT"] = loadOptions.page;
      if (loadOptions.sortBy && loadOptions.sortBy.length > 0)
        this.requestData.sendData["SORT_ORDR"] = loadOptions.sortBy[0];
      else this.requestData.sendData["SORT_ORDR"] = undefined;
      if (loadOptions.sortDesc && loadOptions.sortDesc.length > 0)
        this.requestData.sendData["SORT_DRCT"] = loadOptions.sortDesc[0] ? "desc" : "asc";
      else this.requestData.sendData["SORT_DRCT"] = undefined;

      return this.common_postCall(this.requestData).then((response) => {
        if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
          this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
          return false;
        }

        let header = response.HEADER;
        let data = response.DATA;
        _.each(data, (item) => {
          // 화면에서는 줄바꿈 문자를 \n으로 처리하지만, 서버에서는 \r\n으로 처리하므로 서버에서 가져온 문자열의 줄바꿈 문자를 모두 변경
          item.CONTENT = _.replace(item.CONTENT, /\r\n/gi, "\n");
          // 이행 데이터 중 br tag가 존재하는 경우 있음, 개행문자로 변경
          item.CONTENT = _.replace(item.CONTENT, /<BR>/gi, "\n");
          item.CONTENT = this.restoreXSS(item.CONTENT);
        });

        return {
          data: data,
          totalCount: header.TOT_COUNT,
        };
      });
    },
    search() {
      this.setDataTableParams();

      if (this.dataTableOptions.page > 1) this.dataTableOptions.page = 1;
      else this.dataTableInstance.loadData();
    },
    reset() {
      // 선택된 열 초기화
      this.selectedKey = null;
      this.selectedRows = [];
      // 상세 정보 초기화
      this.repeatDetail = {
        ID: null,
        MAIN_CTGR_CD: null,
        MAIN_CTGR_NM: null,
        MID_CTGR_CD: null,
        MID_CTGR_NM: null,
        SUB_CTGR_CD: null,
        SUB_CTGR_NM: null,
        CONTENT: null,
        SORT_ORD: null,
        USE_YN: null,
        REG_ID: null,
        REG_NM: null
      };
    },
    async save() {
      const length = await this.checkUseList();

      if (!this.repeatDetail.MAIN_CTGR_CD) {
        this.common_alert("상담유형 대분류를 선택해야 합니다.", "noti");
        return false;
      }
      else if (!this.repeatDetail.MID_CTGR_CD) {
        this.common_alert("상담유형 중분류를 선택해야 합니다.", "noti");
        return false;
      }
      else if (!this.repeatDetail.SUB_CTGR_CD) {
        this.common_alert("상담유형 소분류를 선택해야 합니다.", "noti");
      }
      else if (!this.repeatDetail.USE_YN) {
        this.common_alert("사용여부를 선택해야 합니다.", "noti");
        return false;
      }
      else if (!this.repeatDetail.SORT_ORD) {
        this.common_alert("정렬순서를 입력해야 합니다.", "noti");
        return false;
      }
      else if (!/^[0-9]*$/.test(this.repeatDetail.SORT_ORD)) {
        this.common_alert("정렬순서는 숫자만 입력해야 합니다.", "noti");
        return false;
      }
      else if(length > 9 && this.repeatDetail.USE_YN == "Y" && this.selectedRows.length == 0) {
        this.common_alert("사용은 최대 10개까지 가능합니다.", "noti");
        return false;
      }
      else if (length > 10) {
        this.common_alert("사용은 최대 10개까지 가능합니다.", "noti");
        return false;
      }
      else {
        this.common_confirm('저장하시겠습니까?', this.saveRepeat, null, null, null, 'chk');
      }
    },
    saveRepeat() {
      this.isLoading = true;

      // header 세팅
      let requestData = {
        headers: {
          ASYNC: false,
          METHOD: "save",
          SERVICE: "phone.main",
          TYPE: "BIZ_SERVICE",
          URL: "/api/phone/main/repeat/save"
        },
        sendData: {},
      };

      // sendData 세팅
      requestData.sendData = this.repeatDetail;
      requestData.sendData.USER_ID = this.USER_ID;

      this.common_postCall(requestData).then(res => {
        if (this.mixin_isEmpty(res) || res.HEADER.ERROR_FLAG) {
          this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
          return false;
        }
        let data = res.DATA[0];
        // 화면에서는 줄바꿈 문자를 \n으로 처리하지만, 서버에서는 \r\n으로 처리하므로 서버에서 가져온 문자열의 줄바꿈 문자를 모두 변경
        data.CONTENT = _.replace(data.CONTENT, /\r\n/gi, "\n");
        data.CONTENT = this.restoreXSS(data.CONTENT);
        this.selectedRows = res.DATA;
        this.repeatDetail = data;
        this.common_alert("정상 처리되었습니다.", "done");
        this.search();
      }).finally(() => {
        this.isLoading = false;
      });
    },
    delete() {
      this.common_confirm('삭제하시겠습니까?', this.deleteRepeat, null, null, null, 'chk');
    },
    deleteRepeat() {
      this.isLoading = true;

      // header 세팅
      let requestData = {
        headers: {
          ASYNC: false,
          METHOD: "delete",
          SERVICE: "phone.main",
          TYPE: "BIZ_SERVICE",
          URL: "/api/phone/main/repeat/delete"
        },
        sendData: {},
      };

      // sendData 세팅
      requestData.sendData.ID = this.repeatDetail.ID;

      this.common_postCall(requestData).then(res => {
        if (this.mixin_isEmpty(res) || res.HEADER.ERROR_FLAG) {
          this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
          return false;
        }
        this.reset();
        this.common_alert("정상 처리되었습니다.", "done");
        this.search();
      }).finally(() => {
        this.isLoading = false;
      });
    },

    onClickRow: function(item, row) {
      if (this.selectedKey === item.ID) {
        row.select(false);
        this.selectedKey = null;
        this.repeatDetail = {
          ID: null,
          MAIN_CTGR_CD: null,
          MAIN_CTGR_NM: null,
          MID_CTGR_CD: null,
          MID_CTGR_NM: null,
          SUB_CTGR_CD: null,
          SUB_CTGR_NM: null,
          CONTENT: null,
          SORT_ORD: null,
          USE_YN: null,
          REG_ID: null,
          REG_NM: null
        };
      } else {
        row.select(true);
        this.selectedKey = item.ID;
        this.repeatDetail = Object.assign(this.repeatDetail, item);
      }
    },

    filteredUsedCodeList() {
      return _.filter(this.usedCodeList, (item) => {
        return item.value;
      });
    },
    async checkUseList() {

      let data = [];

      let requestData = {
        headers: {
          ASYNC: false,
          METHOD: "delete",
          SERVICE: "phone.main",
          TYPE: "BIZ_SERVICE",
          URL: "/api/phone/main/repeat/list"
        },
        sendData: {},
      };

      requestData.headers["ROW_CNT"] = '50';
      requestData.headers["PAGES_CNT"] = '1';
      requestData.sendData['USER_ID'] = this.USER_ID;
      requestData.sendData['USE_YN'] = 'Y';

      const response = await this.common_postCall(requestData);
      data = response.DATA;
      const length = data.length;

      return length;
    }
  },
  created() {},
  mounted() {
    this.init();
  },
  watch: {
    "repeatDetail.MAIN_CTGR_CD": async function(newValue) {
      if (newValue) {
        const resCategoryList = await this.getCategoryList(newValue);

        if (
          !this.mixin_isEmpty(resCategoryList) &&
          !resCategoryList.HEADER.ERROR_FLAG
        ) {
          let data = resCategoryList.DATA;

          this.middleCategoryList = data;
          this.repeatDetail.MID_CTGR_CD = data[0].value;
        }
      } else {
        this.middleCategoryList = [{ text: '선택', value: '' }];
        this.repeatDetail.MID_CTGR_CD = '';
      }
    },
    "repeatDetail.MID_CTGR_CD": async function(newValue) {
      if (newValue) {
        const resCategoryList = await this.getCategoryList(newValue);

        if (
          !this.mixin_isEmpty(resCategoryList) &&
          !resCategoryList.HEADER.ERROR_FLAG
        ) {
          let data = resCategoryList.DATA;

          this.subCategoryList = data;
          this.repeatDetail.SUB_CTGR_CD = data[0].value;
        }
      } else {
        this.subCategoryList = [];
        this.repeatDetail.SUB_CTGR_CD = '';
      }
    },
  },
};
</script>

<style scoped>
/* .v-application .error--text > .v-input__control {
  border: 1px solid;
} */
</style>